import { VISUALIZATION_TYPE } from "enums";
import { Survey, VisualizerFire } from "interfaces";
import { MapVisualizerProps } from "./MapVisualizer/MapVisualizer.types";

interface AnalyzeViewPageProps {
  surveyTitle?: string;
  surveyMeta?: Survey["meta"];
  surveyId?: string;
  survey?: Survey;
  nAnswerPoints?: number;
  nRespondents?: number;
  nMapMarkers?: number;
  answerDateData?: { date: number; value: number }[];
  onClickExportCsv?: () => void;
  onClickExportXlsx?: () => void;
  onClickExportGeoJson?: () => void;
  onChangeFlatExport?: () => void;
  onClickAddVisualizer?: (type: VISUALIZATION_TYPE) => void;
  onClickFilter?: () => void;
  filterCount?: number;
  onClickDeleteVisualizer?: (id: string) => void;
  onClickResizeVisualizer?: (id: string) => void;
  onClickReturnPage?: () => void;
  onClickSaveDashboard?: (id?: string) => void;
  onClickEditVizualiserFilter?: (visualizerId: string) => void;
  onChangeLayerSettings?: (
    id: string,
    idx: number,
    color: string,
    opacity: string
  ) => void;
  onChangeHeatmapLayerSettings?: MapVisualizerProps["onChangeHeatmapLayerSettings"];
  onChangeLayerRadius?: (id: string, idx: number, radius: number) => void;
  visualizers?: VisualizerFire[];
  globallyFilteredRespondents: {
    answers: any;
    meta: any;
    mapMarkers: any;
  }[];
  isLoading?: boolean;
  onAddHeight?: (id: string) => void;
  onRemoveHeight?: (id: string) => void;
  onAddWidth?: (id: string) => void;
  onRemoveWidth?: (id: string) => void;
}

export enum FILTER_COMPARISON {
  IS = "is",
  IS_NOT = "is not",
  CONTAINS = "contains"
  // GREATER = "greater",
  // LESS = "less",
}

interface AnalyzePageProps {}

export type { AnalyzeViewPageProps };
export default AnalyzePageProps;
