import {
  Badge,
  ButtonBase,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import {
  Layer,
  Map as MapMapbox,
  NavigationControl,
  Source
} from "react-map-gl";
import {
  Add,
  ExpandLess,
  ExpandMore,
  FitScreen,
  Layers,
  Remove,
  Visibility,
  VisibilityOff
} from "@mui/icons-material";
import { mapStyles } from "./MapVisualizer.utils";
import { MapVisualizerViewProps } from "./MapVisualizer.types";
import { useRef, useState } from "react";
import ColorInput from "components/ColorInput";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import mapboxgl from "mapbox-gl";
import { HeatmapSettings, LayerSettings } from "interfaces";

const token =
  "pk.eyJ1IjoiaW5vZGlubyIsImEiOiJjbG5mNWxuN3UwajY4MmtvaHh0dmE1dnBzIn0.3rvwbu7fkYOM9jRMxl7kwA";

const MapListItem = (props: {
  settings: LayerSettings;
  data: {
    name: string;
    show: boolean;
  };
  onChangeLayerSettings: (color: string, opacity: string) => void;
  onChangeLayerRadius: (radius: number) => void;
  onClickLayerToggleShow: () => void;
}) => {
  const {
    settings,
    data,
    onChangeLayerSettings,
    onChangeLayerRadius,
    onClickLayerToggleShow
  } = props;
  const { name, show } = data;

  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItem>
        <Stack direction="row" spacing={1} width="100%">
          <ColorInput
            value={settings.color}
            opacity={settings.opacity}
            trigger={
              <div
                style={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: settings.color,
                  borderRadius: "4px"
                }}
              />
            }
            onChange={(color) => {
              onChangeLayerSettings(
                color.hex,
                color.rgb.a ? color.rgb.a.toString() : ""
              );
            }}
          />
          <ListItemText sx={{ userSelect: "none" }} primary={name} />
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <ButtonBase
              sx={{
                width: "16px",
                height: "16px",
                borderRadius: "4px"
              }}
              onClick={onClickLayerToggleShow}
            >
              {show ? (
                <Visibility sx={{ width: "16px", height: "16px" }} />
              ) : (
                <VisibilityOff sx={{ width: "16px", height: "16px" }} />
              )}
            </ButtonBase>
            <ButtonBase
              sx={{
                width: "16px",
                height: "16px",
                borderRadius: "4px"
              }}
              onClick={() => {
                setOpen((prev) => !prev);
              }}
            >
              {open ? <ExpandLess /> : <ExpandMore />}
            </ButtonBase>
          </Stack>
        </Stack>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List>
          <ListItem>
            <Stack direction="row" width="100%" spacing={1}>
              <Stack direction="row" spacing={1} flex={1}>
                <div
                  style={{
                    width: "12px",
                    height: "12px"
                  }}
                />
                <Stack direction="row" spacing={1} flex={1} alignItems="center">
                  <Typography fontSize="14px">Radie</Typography>
                  <TextField
                    margin="none"
                    variant="standard"
                    sx={{ flex: 1 }}
                    value={isNaN(settings.radius) ? "" : settings.radius}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      onChangeLayerRadius(value);
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </ListItem>
        </List>
      </Collapse>
    </>
  );
};

const MapHeatmapItem = (props: {
  settings: MapVisualizerViewProps["heatmapSettings"];
  show?: boolean;
  onChangeLayerSettings: (
    settings: Partial<MapVisualizerViewProps["heatmapSettings"]>
  ) => void;
  onClickHeatmapIcon?: () => void;
}) => {
  const { settings, show, onChangeLayerSettings, onClickHeatmapIcon } = props;

  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItem>
        <Stack direction="row" spacing={1} width="100%">
          <div
            style={{
              width: "12px",
              height: "12px",
              backgroundColor: "transparent",
              borderRadius: "4px"
            }}
          />

          <ListItemText sx={{ userSelect: "none" }} primary="Heatmap" />
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <ButtonBase
              sx={{
                width: "16px",
                height: "16px",
                borderRadius: "4px"
              }}
              onClick={onClickHeatmapIcon}
            >
              {show ? (
                <Visibility sx={{ width: "16px", height: "16px" }} />
              ) : (
                <VisibilityOff sx={{ width: "16px", height: "16px" }} />
              )}
            </ButtonBase>
            <ButtonBase
              sx={{
                width: "16px",
                height: "16px",
                borderRadius: "4px"
              }}
              onClick={() => {
                setOpen((prev) => !prev);
              }}
            >
              {open ? <ExpandLess /> : <ExpandMore />}
            </ButtonBase>
          </Stack>
        </Stack>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List>
          <ListItem>
            <Stack direction="row" width="100%" spacing={1}>
              <Stack direction="row" spacing={1} flex={1}>
                <div
                  style={{
                    width: "12px",
                    height: "12px"
                  }}
                />
                <Stack direction="row" spacing={1} flex={1} alignItems="center">
                  <Typography fontSize="14px">Radie</Typography>
                  <TextField
                    margin="none"
                    variant="standard"
                    sx={{ flex: 1 }}
                    value={
                      settings.radius && isNaN(settings.radius)
                        ? ""
                        : settings.radius
                    }
                    disabled
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      onChangeLayerSettings({
                        radius: value
                      });
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </ListItem>
          <ListItem>
            <Stack direction="row" width="100%" spacing={1}>
              <Stack direction="row" spacing={1} flex={1}>
                <div
                  style={{
                    width: "12px",
                    height: "12px"
                  }}
                />
                <Stack direction="row" spacing={1} flex={1} alignItems="center">
                  <Typography fontSize="14px">Intensitet</Typography>
                  <TextField
                    margin="none"
                    variant="standard"
                    sx={{ flex: 1 }}
                    value={
                      settings.intensity && isNaN(settings.intensity)
                        ? ""
                        : settings.intensity
                    }
                    disabled
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      onChangeLayerSettings({
                        intensity: value
                      });
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </ListItem>
          <ListItem>
            <Stack direction="row" width="100%" spacing={1}>
              <Stack direction="row" spacing={1} flex={1}>
                <div
                  style={{
                    width: "12px",
                    height: "12px"
                  }}
                />
                <Stack direction="row" spacing={1} flex={1} alignItems="center">
                  <Typography fontSize="14px">Opacitet</Typography>
                  <TextField
                    margin="none"
                    variant="standard"
                    sx={{ flex: 1 }}
                    value={
                      settings.opacity && isNaN(settings.opacity)
                        ? ""
                        : settings.opacity
                    }
                    disabled
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      onChangeLayerSettings({
                        opacity: value
                      });
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </ListItem>
        </List>
      </Collapse>
    </>
  );
};

const MapVisualizerView = (props: MapVisualizerViewProps) => {
  const {
    data,
    layers,
    mapRef,
    filterCount,
    showHeatmap,
    heatmapSettings,
    onClickDelete,
    onClickFilter,
    onClickFitToScreenIcon,
    onClickLayerToggleShow,
    onClickHeatmapIcon,
    onChangeLayerSettings,
    onChangeLayerRadius,
    onChangeHeatmapLayerSettings,
    onAddHeight,
    onAddWidth,
    onRemoveHeight,
    onRemoveWidth,
    height
  } = props;

  const [openLayersMeny, setOpenLayersMeny] = useState(false);
  const [mapStyle, setMapStyle] = useState(mapStyles[0].value);

  const layersButton = useRef(null);

  const handleOnCloseLayersMenu = () => {
    setOpenLayersMeny(false);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        margin: 1,
        backgroundColor: "rgba(0,0,0,0)",
        outline: "1px solid white",
        color: "white",
        height: height ? `${height}px` : "800px",
        padding: 2
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <Stack width="100%" height="100%" position="relative">
          <MapMapbox
            ref={mapRef}
            reuseMaps
            mapLib={mapboxgl}
            mapboxAccessToken={token}
            style={{ flex: 1 }}
            onRender={(event) => event.target.resize()}
            mapStyle={mapStyle}
          >
            <NavigationControl />
            {data.map((d, i) => {
              const { features, show } = d;
              const settings = layers[i];

              return (
                <Source
                  id={`data-${i}`}
                  type="geojson"
                  data={{ type: "FeatureCollection", features }}
                  key={i}
                >
                  {showHeatmap && (
                    <Layer
                      id={`heatmap-${i}`}
                      type="heatmap"
                      source={`data-${i}`}
                      paint={{
                        "heatmap-intensity": Number(heatmapSettings.intensity),
                        "heatmap-radius": Number(heatmapSettings.radius),
                        "heatmap-opacity": Number(heatmapSettings.opacity),
                        "heatmap-color": [
                          "interpolate",
                          ["linear"],
                          ["heatmap-density"],
                          0,
                          "rgba(0, 0, 255, 0)",
                          0.1,
                          "royalblue",
                          0.3,
                          "cyan",
                          0.5,
                          "lime",
                          0.7,
                          "yellow",
                          1,
                          "red"
                        ]
                      }}
                    />
                  )}
                  {show && (
                    <Layer
                      id={`layer-${i}`}
                      type="circle"
                      source={`data-${i}`}
                      paint={{
                        "circle-radius": settings.radius,
                        "circle-color": settings.color,
                        "circle-opacity": settings.opacity,
                        "circle-stroke-color": settings.strokeColor,
                        "circle-stroke-width": settings.strokeWidth,
                        "circle-stroke-opacity": settings.strokeOpacity
                      }}
                    />
                  )}
                </Source>
              );
            })}
          </MapMapbox>
          <Stack position="absolute" left="12px" top="12px">
            <Stack
              width="360px"
              height="100%"
              bgcolor="rgba(255,255,255,0.8)"
              color="black"
              borderRadius="4px"
            >
              <List dense>
                <MapHeatmapItem
                  settings={heatmapSettings}
                  show={showHeatmap}
                  onClickHeatmapIcon={onClickHeatmapIcon}
                  onChangeLayerSettings={(settings) =>
                    onChangeHeatmapLayerSettings(settings)
                  }
                />
                <Divider />
                {data.map((d, i) => (
                  <>
                    <MapListItem
                      key={i}
                      settings={layers[i]}
                      data={d}
                      onChangeLayerSettings={(color, opacity) =>
                        onChangeLayerSettings(i, color, opacity)
                      }
                      onChangeLayerRadius={(radius) =>
                        onChangeLayerRadius(i, radius)
                      }
                      onClickLayerToggleShow={() => onClickLayerToggleShow(i)}
                    />
                    {i < data.length - 1 && <Divider />}
                  </>
                ))}
              </List>
            </Stack>
          </Stack>
          <Stack direction="row" position="absolute" right="50px" top="10px">
            <Tooltip title="Ta bort" placement="left">
              <ButtonBase onClick={onClickDelete}>
                <DeleteIcon
                  sx={{
                    color: "white",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    padding: "4px",
                    borderRadius: "4px"
                  }}
                />
              </ButtonBase>
            </Tooltip>
          </Stack>
          <Stack position="absolute" right="8px" top="120px" spacing={2}>
            <Tooltip title="Filter" placement="left">
              <ButtonBase onClick={onClickFilter}>
                <Badge badgeContent={filterCount} color="primary">
                  <FilterListIcon
                    sx={{
                      color: "white",
                      backgroundColor: "rgba(0,0,0,0.5)",
                      padding: "4px",
                      borderRadius: "4px"
                    }}
                  />
                </Badge>
              </ButtonBase>
            </Tooltip>
            <Tooltip title="Zoom" placement="left">
              <ButtonBase onClick={onClickFitToScreenIcon}>
                <FitScreen
                  sx={{
                    color: "white",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    padding: "4px",
                    borderRadius: "4px"
                  }}
                />
              </ButtonBase>
            </Tooltip>
            <Tooltip title="Lager" placement="left">
              <ButtonBase onClick={() => setOpenLayersMeny(true)}>
                <Layers
                  ref={layersButton}
                  sx={{
                    color: "white",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    padding: "4px",
                    borderRadius: "4px"
                  }}
                />
              </ButtonBase>
            </Tooltip>
            <Stack
              alignItems="center"
              sx={{
                backgroundColor: "rgba(0,0,0,0.5)",
                padding: "2px",
                borderRadius: "4px"
              }}
            >
              <ButtonBase onClick={onAddWidth}>
                <Add sx={{ color: "white" }} />
              </ButtonBase>
              <Typography
                sx={{
                  userSelect: "none"
                }}
                fontSize="12px"
              >
                Bredd
              </Typography>
              <ButtonBase onClick={onRemoveWidth}>
                <Remove sx={{ color: "white" }} />
              </ButtonBase>
            </Stack>
            <Stack
              alignItems="center"
              sx={{
                backgroundColor: "rgba(0,0,0,0.5)",
                padding: "2px",
                borderRadius: "4px"
              }}
            >
              <ButtonBase onClick={onAddHeight}>
                <Add sx={{ color: "white" }} />
              </ButtonBase>
              <Typography
                sx={{
                  userSelect: "none"
                }}
                fontSize="12px"
              >
                Höjd
              </Typography>
              <ButtonBase onClick={onRemoveHeight}>
                <Remove sx={{ color: "white" }} />
              </ButtonBase>
            </Stack>
            <Menu
              id="basic-menu"
              anchorEl={layersButton.current}
              open={openLayersMeny}
              onClose={handleOnCloseLayersMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button"
              }}
            >
              {mapStyles.map((x) => (
                <MenuItem
                  onClick={() => {
                    setMapStyle(x.value);
                    handleOnCloseLayersMenu();
                  }}
                  key={x.value}
                >
                  {x.label}
                </MenuItem>
              ))}
            </Menu>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default MapVisualizerView;
