import {
  convertFireAnswers,
  convertMarkersToGeoJsonExport,
  mapQuestionsToCsvMap,
  mapRespondentAnswers,
  sameDay
} from "./AnalyzePage.utils";
import {
  Dashboard,
  FilterFire,
  GraphVisualizer,
  MapVisualizer,
  Survey,
  VisualizerFire
} from "interfaces";
import { fire } from "services";
import { GRAPH_TYPE } from "./DataVisualizer/DataVisualizer.types";
import { nanoid } from "nanoid";
import { Timestamp, Unsubscribe } from "firebase/firestore";
import { useCallback, useEffect, useRef, useState } from "react";
import { useImmer } from "use-immer";
import { useNavigate, useParams } from "react-router-dom";
import { VISUALIZATION_TYPE } from "enums";
import { writeFileXLSX, utils } from "xlsx";
import AnalyzeProps, { FILTER_COMPARISON } from "./AnalyzePage.types";
import AnalyzeViewPage from "./AnalyzePage.view";
import download from "downloadjs";
import FilterModal from "./FilterModal";
import GraphAddModal from "./GraphAddModal";
import MapAddModal from "./MapAddModal";
import randomColor from "randomcolor";

const AnalyzePage = (props: AnalyzeProps) => {
  const { id, shortId } = useParams();

  const navigate = useNavigate();

  const unsubscribeAnswers = useRef<void | Unsubscribe>();
  const unsubscribeSurveys = useRef<void | Unsubscribe>();

  const [isLoading, setIsLoading] = useState(true);
  const [fireId, setFireId] = useState<string>();
  const [isOpenGraphAddModal, setIsOpenGraphAddModal] = useState(false);
  const [isOpenMapAddModal, setIsOpenMapAddModal] = useState(false);
  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);
  const [selectedVisualizerId, setSelectedVisualizerId] = useState<string>();
  const [filters, setFilters] = useState<FilterFire[]>([]);
  const [survey, setSurvey] = useState<Survey>();
  const [filteredRespondents, setFilteredRespondents] = useState<
    {
      answers: any;
      meta: any;
      mapMarkers: any;
    }[]
  >([]);
  const [respondents, setRespondents] = useState<
    { answers: any; meta: any; mapMarkers: any }[]
  >([]);
  const [visualizers, setVisualizers] = useImmer<VisualizerFire[]>([]);
  const [nAnswerPoints, setNAnswerPoints] = useState(0);
  const [nMapMarkers, setNMapMarkers] = useState(0);
  const [answerDateData, setAnswerDateData] = useState<
    { date: number; value: number }[]
  >([]);
  const [splitCsvWithOptionColumns, setSplitCsvWithOptionColumns] =
    useState(false);

  const handleOnChangeFlatExport = useCallback(() => {
    setSplitCsvWithOptionColumns((prev) => !prev);
  }, []);

  const handleOnError = useCallback(() => {}, []);

  const handleOnClickReturnPage = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleOnClickAddDataVisualizer = useCallback(
    (type: VISUALIZATION_TYPE) => {
      if (type === VISUALIZATION_TYPE.MAP) {
        setIsOpenMapAddModal(true);
      } else if (type === VISUALIZATION_TYPE.GRAPH) {
        setIsOpenGraphAddModal(true);
      }
    },
    []
  );

  const handleOnClickFilter = useCallback(() => {
    setIsOpenFilterModal(true);
  }, []);

  const handleOnClickAddFilter = useCallback(
    (filter: FilterFire) => {
      if (selectedVisualizerId) {
        setVisualizers((prev) => {
          const selectedVisualizer = prev.find(
            (v) => v.id === selectedVisualizerId
          );
          if (selectedVisualizer) {
            selectedVisualizer.filters.push(filter);
          }
        });
      } else {
        setFilters((prev) => {
          return [...prev, filter];
        });
      }
    },
    [selectedVisualizerId, setVisualizers]
  );

  const handleOnChangeLayerSettings = useCallback(
    (id: string, idx: number, color: string, opacity: string) => {
      setVisualizers((draft) => {
        const visualizer = draft.find((v) => v.id === id) as MapVisualizer;
        if (visualizer) {
          visualizer.layers[idx].color = color;
          visualizer.layers[idx].opacity = parseFloat(opacity);
        }
      });
    },
    [setVisualizers]
  );

  const handleOnChangeHeatmapLayerSettings = useCallback(() => {}, []);

  const handleOnChangeLayerRadius = useCallback(
    (id: string, idx: number, radius: number) => {
      setVisualizers((draft) => {
        const visualizer = draft.find((v) => v.id === id) as MapVisualizer;
        if (visualizer) {
          visualizer.layers[idx].radius = radius;
        }
      });
    },
    [setVisualizers]
  );

  const handleOnClickRemoveFilter = useCallback(
    (filter: FilterFire) => {
      if (selectedVisualizerId) {
        setVisualizers((prev) => {
          const selectedVisualizer = prev.find(
            (v) => v.id === selectedVisualizerId
          );
          if (selectedVisualizer) {
            selectedVisualizer.filters = selectedVisualizer.filters.filter(
              (f) => f.id !== filter.id
            );
          }
        });
      } else {
        setFilters((prev) => {
          return prev.filter((f) => f.id !== filter.id);
        });
      }
    },
    [selectedVisualizerId, setVisualizers]
  );

  const handleOnCloseFilterModal = useCallback(() => {
    setIsOpenFilterModal(false);
    setSelectedVisualizerId(undefined);
  }, []);

  const handleOnClickAddGraph = useCallback(
    (dataVisualizer: { questionId: string; visualisationType: GRAPH_TYPE }) => {
      setVisualizers((draft) => {
        const newGraphVisualizer: GraphVisualizer = {
          id: nanoid(),
          questionId: dataVisualizer.questionId,
          chartType: dataVisualizer.visualisationType,
          size: 12,
          height: 500,
          width: 12,
          type: VISUALIZATION_TYPE.GRAPH,
          filters: []
        };

        draft.push(newGraphVisualizer);
      });

      setIsOpenGraphAddModal(false);
    },
    [setVisualizers]
  );

  const handleOnClickAddMap = useCallback(
    (mapVisualizer: { questionIds: string[]; questionLabel: string }) => {
      setIsOpenMapAddModal(false);

      setVisualizers((draft) => {
        const newGraphVisualizer: MapVisualizer = {
          id: nanoid(),
          questionIds: mapVisualizer.questionIds,
          size: 12,
          height: 500,
          width: 12,
          type: VISUALIZATION_TYPE.MAP,
          filters: [],
          layers: mapVisualizer.questionIds.map(() => {
            const color = randomColor();

            return {
              radius: 5,
              color: color,
              opacity: 0.5,
              strokeColor: color,
              strokeOpacity: 1,
              strokeWidth: 1
            };
          })
        };

        draft.push(newGraphVisualizer);
      });
    },
    [setVisualizers]
  );

  const handleOnCloseGraphAddModal = useCallback(() => {
    setIsOpenGraphAddModal(false);
  }, []);

  const handleOnCloseMapAddModal = useCallback(() => {
    setIsOpenMapAddModal(false);
  }, []);

  const handleOnClickDeleteVisualizer = useCallback(
    (id: string) => {
      setVisualizers((draft) => draft.filter((dv) => dv.id !== id));
    },
    [setVisualizers]
  );

  const handleOnClickEditVizualiserFilter = useCallback((id: string) => {
    setSelectedVisualizerId(id);
    setIsOpenFilterModal(true);
  }, []);

  const handleOnClickResizeVisualizer = useCallback(
    (id: string) => {
      setVisualizers((draft) => {
        return draft.map((dv) => {
          if (dv.id === id) {
            return {
              ...dv,
              size: dv.size ? (dv.size === 12 ? 6 : 12) : 6
            };
          }
          return dv;
        });
      });
    },
    [setVisualizers]
  );

  const handleOnClickExportGeoJson = useCallback(() => {
    /** Early abort */
    if (!survey || respondents.length === 0) {
      console.error("handleOnClickExportGeoJson: no survey or respondents");
      return false;
    }

    const geojson = convertMarkersToGeoJsonExport(
      respondents
        .map((r) =>
          r.mapMarkers.map((m: any) => ({ ...m, respondentId: r.meta.id }))
        )
        .flat()
    );

    download(
      new Blob([JSON.stringify(geojson)]),
      `mapx-export_${new Date().toISOString().split("T")[0]}_${
        survey.id?.split("/")[0]
      }-${survey.id?.split("/")[1]}.geojson`,
      "application/json"
    );
  }, [respondents, survey]);

  const handleOnClickExportCsv = useCallback(() => {
    /** Early abort */
    if (!survey || respondents.length === 0) {
      console.error("handleOnClickExportCsv: no survey or respondents");
      return false;
    }

    /** Data array */
    const exportData: string[][] = [];

    /** Extract a map of question-full-id -> question-label */
    const mappedSurveyQuestions = mapQuestionsToCsvMap(
      survey,
      splitCsvWithOptionColumns
    );

    /** Push the headers */
    const headerLabels = Array.from(mappedSurveyQuestions.values());
    exportData.push(headerLabels);
    /** Extract header ids */
    const headerIds = Array.from(mappedSurveyQuestions.keys());

    /** Iterate over each respondent and for each header id, find the answer id and push its label */
    for (let i = 0; i < respondents.length; i++) {
      const respondent = respondents[i];

      const mappedRespondentAnswers = mapRespondentAnswers(
        respondent,
        splitCsvWithOptionColumns
      );

      const { meta } = respondent;
      exportData.push(
        headerIds.map((mqk) => {
          if (mappedRespondentAnswers[mqk]) {
            return mappedRespondentAnswers[mqk];
          }
          if (mqk === "responseTimestamp") {
            return meta.created.toDate().toLocaleString();
          }
          if (mqk === "respondentId") {
            return meta.fireId;
          }

          return "null";
        })
      );
    }

    let csvContent = "";
    exportData.forEach((row) => {
      row.forEach((cell) => {
        csvContent += `"${cell}",`;
      });
      csvContent += "\n";
    });

    download(
      new Blob([csvContent]),
      `mapx-export_${new Date().toISOString().split("T")[0]}_${
        survey.id?.split("/")[0]
      }-${survey.id?.split("/")[1]}.csv`,
      "text/csv;charset=utf-8"
    );
  }, [survey, respondents, splitCsvWithOptionColumns]);

  const handleOnClickExportXlsx = useCallback(() => {
    /** Early abort */
    if (!survey || respondents.length === 0) {
      console.error("handleOnClickExportXlsx: no survey or respondents");
      return false;
    }

    /** Data array */
    const exportData: string[][] = [];

    /** Extract a map of question-full-id -> question-label */
    const mappedSurveyQuestions = mapQuestionsToCsvMap(
      survey,
      splitCsvWithOptionColumns
    );

    /** Push the headers */
    const headerLabels = Array.from(mappedSurveyQuestions.values());
    exportData.push(headerLabels);
    /** Extract header ids */
    const headerIds = Array.from(mappedSurveyQuestions.keys());

    /** Iterate over each respondent and for each header id, find the answer id and push its label */
    for (let i = 0; i < respondents.length; i++) {
      const respondent = respondents[i];

      const mappedRespondentAnswers = mapRespondentAnswers(
        respondent,
        splitCsvWithOptionColumns
      );

      const { meta } = respondent;
      exportData.push(
        headerIds.map((mqk) => {
          if (mappedRespondentAnswers[mqk]) {
            return mappedRespondentAnswers[mqk];
          }
          if (mqk === "responseTimestamp") {
            return meta.created.toDate().toLocaleString();
          }
          if (mqk === "respondentId") {
            return meta.fireId;
          }

          return "null";
        })
      );
    }

    var worksheet = utils.aoa_to_sheet(exportData);
    var workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Results");
    writeFileXLSX(
      workbook,
      `mapx-export_${new Date().toISOString().split("T")[0]}_${
        survey.id?.split("/")[0]
      }-${survey.id?.split("/")[1]}.xlsx`
    );
  }, [survey, respondents, splitCsvWithOptionColumns]);

  const handleOnClickSaveDashboard = useCallback(() => {
    const user = fire.getUser();

    if (!user) {
      console.error("handleOnClickSaveDashboard: no user");
      return;
    }
    if (!survey?.fireId) {
      console.error("handleOnClickSaveDashboard: no survey");
      return;
    }

    const dashboard: Dashboard = {
      surveyFireId: survey?.fireId,
      created: Timestamp.now(),
      createdByUid: user.uid,
      edited: Timestamp.now(),
      editedByUid: user.uid,
      name: "default",
      filters,
      visualizers
    };

    fire.saveDashboard(dashboard, fireId).then((doc) => {
      if (doc) setFireId(doc.id);
    });
  }, [filters, fireId, survey?.fireId, visualizers]);

  useEffect(() => {
    if (id && shortId) {
      unsubscribeSurveys.current = fire.subscribeToSurvey({
        shortId,
        id,
        onSuccess: (fireSurvey) => {
          setSurvey(fireSurvey);
        },
        onError: () => {
          handleOnError();
        },
        skipPublishCheck: true
      });
    }
  }, [handleOnError, id, shortId]);

  useEffect(() => {
    if (id && shortId && survey) {
      unsubscribeAnswers.current = fire.subscribeToAnswers({
        id,
        shortId,
        onSuccess: (fireAnswers) => {
          const answers = convertFireAnswers(fireAnswers, survey);
          setRespondents(answers);

          setIsLoading((prev) => (prev ? false : false));
        },
        onError: (error) => {
          handleOnError();
        }
      });
    }
  }, [handleOnError, id, shortId, survey]);

  useEffect(() => {
    const nSubAnswers = filteredRespondents
      .map((answer) => answer.answers.length)
      .reduce((acc, curr) => acc + curr, 0);

    const nSubMapMarkers = filteredRespondents
      .map((answer) => answer.mapMarkers.length)
      .reduce((acc, curr) => acc + curr, 0);

    setNAnswerPoints(nSubAnswers);
    setNMapMarkers(nSubMapMarkers);
  }, [filteredRespondents]);

  useEffect(() => {
    /** Extract firestore created timestamps for each answer */
    const answersDates = filteredRespondents
      .map((answer) => answer.meta.created)
      .map((timestamp: Timestamp) => timestamp.toDate());

    /** Count the number of answers per day */
    const dateData = answersDates
      .reduce((acc, date) => {
        const dateIndex = acc.findIndex((d) => sameDay(d.date, date));

        if (dateIndex === -1) {
          acc.push({ date, value: 1 });
        } else {
          acc[dateIndex].value += 1;
        }

        return acc;
      }, [] as { date: Date; value: number }[])
      .map((d) => ({
        date: d.date.getTime(),
        value: d.value
      }))
      .sort((a, b) => a.date - b.date);

    setAnswerDateData(dateData);
  }, [filteredRespondents]);

  useEffect(() => {
    if (filters.length === 0) {
      setFilteredRespondents(respondents);
      return;
    }

    const filteredRespondents = respondents.filter((r) => {
      return filters.every((f) => {
        const questionResponse = r.answers.find(
          (a: any) => a.question.id === f.questionId
        );
        /** If the question is not answered, return false */
        if (questionResponse === undefined) return false;
        const { answer } = questionResponse;
        if (Array.isArray(answer)) {
          if (f.filterComparison === FILTER_COMPARISON.IS) {
            return answer.includes(f.filterValue);
          }
          if (f.filterComparison === FILTER_COMPARISON.IS_NOT) {
            return !answer.includes(f.filterValue);
          }
          if (f.filterComparison === FILTER_COMPARISON.CONTAINS) {
            return answer.includes(f.filterValue);
          }
        } else {
          if (f.filterComparison === FILTER_COMPARISON.IS) {
            return answer === f.filterValue;
          }
          if (f.filterComparison === FILTER_COMPARISON.IS_NOT) {
            return answer !== f.filterValue;
          }
          if (f.filterComparison === FILTER_COMPARISON.CONTAINS) {
            return answer.includes(f.filterValue);
          }
        }
        return false;
      });
    });

    setFilteredRespondents(filteredRespondents);
  }, [filters, respondents]);

  useEffect(() => {
    const user = fire.getUser();

    if (!user) {
      console.error("useEffect: no user");
      return;
    }

    if (!survey) {
      console.error("useEffect: no survey");
      return;
    }

    fire
      .getDashboard({
        uid: user.uid,
        surveyFireId: survey?.fireId
      })
      .then((dashboards) => {
        setVisualizers(dashboards?.visualizers || []);
        setFilters(dashboards?.filters || []);
        setFireId(dashboards?.fireId);
      });
  }, [setVisualizers, survey]);

  const handleOnAddHeightVisualiser = useCallback(
    (id: string) => {
      setVisualizers((draft) => {
        const visualizer = draft.find((v) => v.id === id);
        if (visualizer) {
          visualizer.height = visualizer.height + 100;
        }
      });
    },
    [setVisualizers]
  );

  const handleOnAddWidthVisualiser = useCallback(
    (id: string) => {
      setVisualizers((draft) => {
        const visualizer = draft.find((v) => v.id === id);
        if (visualizer) {
          visualizer.width = Math.min(visualizer.width + 1, 12);
        }
      });
    },
    [setVisualizers]
  );

  const handleOnRemoveHeightVisualiser = useCallback(
    (id: string) => {
      setVisualizers((draft) => {
        const visualizer = draft.find((v) => v.id === id);
        if (visualizer) {
          visualizer.height = Math.max(visualizer.height - 100, 500);
        }
      });
    },
    [setVisualizers]
  );

  const handleOnRemoveWidthVisualiser = useCallback(
    (id: string) => {
      setVisualizers((draft) => {
        const visualizer = draft.find((v) => v.id === id);
        if (visualizer) {
          visualizer.width = Math.max(visualizer.width - 1, 1);
        }
      });
    },
    [setVisualizers]
  );

  return (
    <>
      <AnalyzeViewPage
        surveyTitle={survey?.name}
        surveyId={survey?.id}
        surveyMeta={survey?.meta}
        nAnswerPoints={nAnswerPoints}
        nMapMarkers={nMapMarkers}
        answerDateData={answerDateData}
        onClickExportCsv={handleOnClickExportCsv}
        onClickExportXlsx={handleOnClickExportXlsx}
        onClickExportGeoJson={handleOnClickExportGeoJson}
        onChangeFlatExport={handleOnChangeFlatExport}
        onClickReturnPage={handleOnClickReturnPage}
        onClickAddVisualizer={handleOnClickAddDataVisualizer}
        onClickDeleteVisualizer={handleOnClickDeleteVisualizer}
        onClickEditVizualiserFilter={handleOnClickEditVizualiserFilter}
        onClickResizeVisualizer={handleOnClickResizeVisualizer}
        onClickSaveDashboard={handleOnClickSaveDashboard}
        onClickFilter={handleOnClickFilter}
        onChangeLayerSettings={handleOnChangeLayerSettings}
        onChangeHeatmapLayerSettings={handleOnChangeHeatmapLayerSettings}
        onChangeLayerRadius={handleOnChangeLayerRadius}
        visualizers={visualizers}
        nRespondents={filteredRespondents.length}
        filterCount={filters?.length}
        globallyFilteredRespondents={filteredRespondents}
        survey={survey}
        isLoading={isLoading}
        onAddHeight={handleOnAddHeightVisualiser}
        onRemoveHeight={handleOnRemoveHeightVisualiser}
        onAddWidth={handleOnAddWidthVisualiser}
        onRemoveWidth={handleOnRemoveWidthVisualiser}
      />
      <MapAddModal
        isOpen={isOpenMapAddModal}
        onClose={handleOnCloseMapAddModal}
        onAdd={handleOnClickAddMap}
        survey={survey}
      />
      <GraphAddModal
        isOpen={isOpenGraphAddModal}
        onClose={handleOnCloseGraphAddModal}
        onAdd={handleOnClickAddGraph}
        survey={survey}
      />
      <FilterModal
        selectedVisualizer={visualizers.find(
          (v) => v.id === selectedVisualizerId
        )}
        globalFilters={filters}
        onClose={handleOnCloseFilterModal}
        isOpen={isOpenFilterModal}
        onClickAddFilter={handleOnClickAddFilter}
        onRemoveFilter={handleOnClickRemoveFilter}
        survey={survey}
      />
    </>
  );
};

export default AnalyzePage;
