import {
  extractAllSurveyQuestions,
  mapMarkersToGeoJson
} from "../AnalyzePage.utils";
import { FILTER_COMPARISON } from "../AnalyzePage.types";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { HeatmapSettings } from "interfaces";
import { MapRef } from "react-map-gl";
import { MapVisualizerProps, MapVisualizerRef } from "./MapVisualizer.types";
import { Question } from "types";
import { QUESTION_TYPE } from "enums";
import { useImmer } from "use-immer";
import MapVisualizerView from "./MapVisualizer.view";
import mapboxgl from "mapbox-gl";

const MapVisualizer = forwardRef<MapVisualizerRef, MapVisualizerProps>(
  (props) => {
    const {
      filters,
      globallyFilteredRespondents,
      graphQuestionIds,
      height,
      id,
      layers,
      onAddHeight,
      onAddWidth,
      onChangeLayerSettings,
      onChangeHeatmapLayerSettings,
      onChangeLayerRadius,
      onClickDelete,
      onClickFilter,
      onRemoveHeight,
      onRemoveWidth,
      survey
    } = props;

    const [data, setData] = useImmer<
      {
        name: string;
        features: any;
        show: boolean;
      }[]
    >([]);

    const [heatmapSettings] = useImmer<HeatmapSettings>({
      intensity: 2,
      radius: 20,
      opacity: 0.2
    });

    const [filteredRespondents, setFilteredRespondents] = useState<
      {
        answers: any;
        meta: any;
        mapMarkers: any;
      }[]
    >([]);

    const [showHeatmap, setShowHeatmap] = useState(false);

    const mapRef = useRef<MapRef>(null);

    const hasMapFitted = useRef(false);

    const fitToScreen = useCallback(
      (hasFitted?: () => void) => {
        const dataCoords = data.map((d) => d.features).flat();

        if (
          mapRef.current &&
          data &&
          data.length > 0 &&
          dataCoords.length >= 2
        ) {
          const mapCoordinates = dataCoords.map(
            (feature: any) => feature.geometry.coordinates
          );
          const bounds = new mapboxgl.LngLatBounds();
          mapCoordinates.forEach((coordinates: any) => {
            bounds.extend(coordinates);
          });
          mapRef.current.fitBounds(bounds, {
            padding: 20
          });
          if (hasFitted) hasFitted();
        }
      },
      [data]
    );

    const handleOnClickDelete = useCallback(() => {
      if (onClickDelete) {
        onClickDelete(id);
      }
    }, [id, onClickDelete]);

    const handleOnClickFilter = useCallback(() => {
      if (onClickFilter) {
        onClickFilter(id);
      }
    }, [id, onClickFilter]);

    const handleOnClickFitToScreenIcon = useCallback(() => {
      fitToScreen();
    }, [fitToScreen]);

    const handleOnClickHeatmapIcon = useCallback(() => {
      setShowHeatmap((prev) => {
        const newState = !prev;

        return newState;
      });
    }, []);

    const handleOnChangeLayerSetting = (
      idx: number,
      color: string,
      opacity: string
    ) => {
      if (onChangeLayerSettings) {
        onChangeLayerSettings(id, idx, color, opacity);
      }
    };

    const handleOnChangeHeatmapLayerSetting: MapVisualizerProps["onChangeHeatmapLayerSettings"] =
      (props) => {
        if (onChangeHeatmapLayerSettings) {
          onChangeHeatmapLayerSettings(props);
        }
      };

    const handleOnChangeLayerRadius = (idx: number, radius: number) => {
      if (onChangeLayerRadius) {
        onChangeLayerRadius(id, idx, radius);
      }
    };

    useEffect(() => {
      if (filters.length === 0) {
        setFilteredRespondents(globallyFilteredRespondents);
        return;
      }

      const filteredRespondents = globallyFilteredRespondents.filter((r) => {
        return filters.every((f) => {
          const questionResponse = r.answers.find(
            (a: any) => a.question.id === f.questionId
          );
          /** If the question is not answered, return false */
          if (questionResponse === undefined) return false;
          const { answer } = questionResponse;
          if (f.filterComparison === FILTER_COMPARISON.IS) {
            return answer === f.filterValue;
          }
          if (f.filterComparison === FILTER_COMPARISON.IS_NOT) {
            return answer !== f.filterValue;
          }
          if (f.filterComparison === FILTER_COMPARISON.CONTAINS) {
            return answer.includes(f.filterValue);
          }
          return false;
        });
      });
      setFilteredRespondents(filteredRespondents);
    }, [filters, globallyFilteredRespondents]);

    useEffect(() => {
      if (!survey || !graphQuestionIds || graphQuestionIds.length === 0) return;

      const questions = extractAllSurveyQuestions(survey);

      const graphQuestions: (Question & { pageIdx: number })[] = [];
      graphQuestionIds.forEach((id) => {
        const questionHit = questions.find((q) => q.id === id);

        if (questionHit) {
          graphQuestions.push(questionHit);
        }
      });

      if (graphQuestions.length === 0) return;

      const mapDataQuestions: { name: string; features: any }[] = [];

      graphQuestions.forEach((graphQuestion, idx) => {
        const { type, name } = graphQuestion;

        if (type === QUESTION_TYPE.MAP_MARKER) {
          const mapMarkers = filteredRespondents
            .map((respondent) => respondent.mapMarkers)
            .flat();

          const mapQuestionIds = mapMarkers.map(
            (mapMarker) => mapMarker.mapQuestionId
          );

          if (mapQuestionIds.every((id) => id !== undefined)) {
            const filteredMapMarkers = mapMarkers.filter(
              (mapMarker) => mapMarker.mapQuestionId === graphQuestionIds[idx]
            );

            const mapData = mapMarkersToGeoJson(filteredMapMarkers);

            if (mapData.features.length > 0)
              mapDataQuestions.push({ ...mapData, name });
          } else {
            const mapData = mapMarkersToGeoJson(mapMarkers);

            if (mapData.features.length > 0)
              mapDataQuestions.push({ ...mapData, name });
          }
        }
      });

      setData(() =>
        mapDataQuestions.map((d, i) => {
          return {
            ...d,
            show: true
          };
        })
      );
    }, [filteredRespondents, graphQuestionIds, layers, setData, survey]);

    useEffect(() => {
      if (!hasMapFitted.current)
        fitToScreen(() => {
          hasMapFitted.current = true;
        });
    }, [fitToScreen]);

    const handleOnClickLayerToggleShow = (idx: number) => {
      setData((draft) => {
        draft[idx].show = !draft[idx].show;
      });
    };

    const handleOnAddHeight = () => {
      if (onAddHeight) {
        onAddHeight(id);

        setTimeout(() => {
          fitToScreen();
        }, 200);
      }
    };

    const handleOnAddWidth = () => {
      if (onAddWidth) {
        onAddWidth(id);

        setTimeout(() => {
          fitToScreen();
        }, 200);
      }
    };

    const handleOnRemoveHeight = () => {
      if (onRemoveHeight) {
        onRemoveHeight(id);

        setTimeout(() => {
          fitToScreen();
        }, 200);
      }
    };

    const handleOnRemoveWidth = () => {
      if (onRemoveWidth) {
        onRemoveWidth(id);

        setTimeout(() => {
          fitToScreen();
        }, 200);
      }
    };

    return (
      <MapVisualizerView
        data={data}
        layers={layers}
        filterCount={filters.length}
        showHeatmap={showHeatmap}
        heatmapSettings={heatmapSettings}
        onClickFilter={handleOnClickFilter}
        onClickDelete={handleOnClickDelete}
        onClickLayerToggleShow={handleOnClickLayerToggleShow}
        onClickFitToScreenIcon={handleOnClickFitToScreenIcon}
        onClickHeatmapIcon={handleOnClickHeatmapIcon}
        onChangeLayerSettings={handleOnChangeLayerSetting}
        onChangeHeatmapLayerSettings={handleOnChangeHeatmapLayerSetting}
        onChangeLayerRadius={handleOnChangeLayerRadius}
        onAddHeight={handleOnAddHeight}
        onAddWidth={handleOnAddWidth}
        onRemoveHeight={handleOnRemoveHeight}
        onRemoveWidth={handleOnRemoveWidth}
        mapRef={mapRef}
        height={height}
      />
    );
  }
);

export default MapVisualizer;
