import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemButton,
  ListItemText,
  Stack,
  List
} from "@mui/material";
import { getPossibleMapVisualizers } from "../AnalyzePage.utils";
import { useCallback, useMemo, useState } from "react";
import MapAddModalProps from "./MapAddModal.types";

const MapAddModal = (props: MapAddModalProps) => {
  const { survey, onAdd, onClose, isOpen } = props;

  const [selectedQuestionIds, setSelectedQuestionIds] = useState<string[]>([]);

  const possibleMapVisualizers = useMemo(() => {
    if (!survey) return [];
    const p = getPossibleMapVisualizers(survey);
    return p;
    /** Extract questions, their possible graph visualization types and the data for each visualisaiton */
  }, [survey]);

  const handleOnClickAddMap = useCallback(() => {
    if (possibleMapVisualizers.length === 0 || !selectedQuestionIds || !onAdd)
      return;

    const selectedMapVisualizer = possibleMapVisualizers.find((pdv) =>
      selectedQuestionIds.includes(pdv.questionId)
    );
    if (!selectedMapVisualizer) return;

    const mapVisualizer = {
      questionIds: selectedQuestionIds,
      questionLabel: selectedMapVisualizer.questionLabel
    };

    onAdd(mapVisualizer);

    setSelectedQuestionIds([]);
  }, [onAdd, possibleMapVisualizers, selectedQuestionIds]);

  return (
    <Dialog
      className="graph-add-modal"
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>Lägg till karta</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ padding: 1 }}>
          <List>
            {possibleMapVisualizers.map((x) => (
              <ListItemButton
                onClick={() => {
                  setSelectedQuestionIds((prev) => {
                    if (prev.includes(x.questionId)) {
                      return prev.filter((y) => y !== x.questionId);
                    }
                    return [...prev, x.questionId];
                  });
                }}
                key={x.questionId}
                selected={selectedQuestionIds.includes(x.questionId)}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "lightgray"
                  }
                }}
              >
                <ListItemText>{x.questionLabel}</ListItemText>
              </ListItemButton>
            ))}
          </List>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={onClose}>
          Avbryt
        </Button>
        <Button size="small" onClick={handleOnClickAddMap}>
          Lägg till karta
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MapAddModal;
