import { format } from "date-fns";

export const dateFormatter = (date: Date) => {
  return format(new Date(date), "dd-MM-yy");
};

export const mapStyles = [
  { label: "Satellit", value: "mapbox://styles/mapbox/satellite-v9" },
  { label: "Vägar", value: "mapbox://styles/mapbox/streets-v11" },
  { label: "Utomhus", value: "mapbox://styles/mapbox/outdoors-v12" },
  {
    label: "Satellit vägar",
    value: "mapbox://styles/mapbox/satellite-streets-v12"
  },
  { label: "Mörk", value: "mapbox://styles/mapbox/dark-v11" },
  { label: "Ljus", value: "mapbox://styles/mapbox/light-v11" }
];
